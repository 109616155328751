import React, { Component } from "react";
import PageContent from "../Misc/PageContent";
import SearchBar from "../Topbar/SearchBar";
import Colors from "../../extras/Colors";
import SectionHeadline from "../Misc/SectionHeadline";
import FSKIcon from "../../img/fsk_icon.png";
import { Typography } from "@material-ui/core";

const styles = {
  content: {
    padding: "115px 50px 30px 50px",
    minHeight: "100%",
    flex: 1,
    color: Colors.grey,
    '@media (max-width: 320px)': {
      padding: "195px 50px 30px 50px"
    },
    '@media (max-width: 370px) and (min-width: 321px)': {
      padding: "195px 50px 30px 50px"
    },
    '@media (max-width: 480px) and (min-width: 371px)': {
      padding: "195px 50px 30px 50px"
    },
    '@media (max-width: 767px) and (min-width: 481px)': {
      padding: "195px 50px 30px 50px"
    }
  }, 
  universal: {
    fontSize: 14,
    fontFamily: "Helvetica Neue",
    fontWeight: 500,
    marginBottom:20
  },
  externalLink: {
    fontSize: 14,
    color: Colors.fontGrey,
    fontFamily: "Helvetica Neue",
    fontWeight: 500,
    "&:hover": {
      color: Colors.fontGrey
    } 
  }
};

class LegalContent extends Component {
  render() {
    const currentYear = new Date().getFullYear();
    return (
      <PageContent>
        <SearchBar />
        <div  style={styles.content}>
        <SectionHeadline style={{marginBottom: 20}}>Impressum</SectionHeadline>
          <Typography color="primary" variant="body2" style={{marginTop:-20}}>
            <p style={styles.universal}>
              Universal Pictures International Germany GmbH 
            </p>
            <p>
                Herriotstraße 6 - 8
                <br />
                60528 Frankfurt
                <br />
                Tel.: +49 (0)69 / 2222 821- 0 (Zentrale)
                <br /><br />
                Geschäftsführer: Karel de Vries
                <br />
                UST-IdNr: DE 114 105 186
                <br />
                Registergericht: Sitz Frankfurt am Main
                <br />
                Handelsregister: HR Frankfurt B11 205
                <br /><br />
                Bei Fragen zum Thema Universal Pictures / Presse und Pressematerialien kontaktieren Sie bitte:
                <br />
                <a style={styles.externalLink} href="mailto:info@upig.de">info@upig.de</a>
                <br /><br />
                Bei Fragen / Anregungen zur Website kontaktieren Sie bitte:
                <br />
                <a style={styles.externalLink} href="mailto:webmaster-universal@nasa30.com">webmaster-universal@nasa30.com</a>
                <br /><br />
                Jugendschutzbeauftragter:
                <br />
                FSK Freiwillige Selbstkontrolle der Filmwirtschaft GmbH
                <br />
                Murnaustr. 6, 65189 Wiesbaden
                <br /><br />
                <a style={styles.externalLink} target="_blank" href="http://www.fsk.de/BeschwerdeJugendschutzbeauftragter">Kontakt</a>
                <br />
                <a style={styles.externalLink} target="_blank" href="http://www.fsk.de">Homepage</a>
                <br />
                <img style={{marginTop:20}} src={FSKIcon} />
                <br />
                <br />
                <span style={{fontSize: 11, position: "relative", top:-3}}>COPYRIGHT © {currentYear} UNIVERSAL STUDIOS</span>
            </p>
          </Typography>
      </div>
    </PageContent>
    );
  }
}

export default LegalContent;
